import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import app from './firebase'; // Import firebase configuration

// import Mosh from "./mosh";

const x = document.getElementById("root");
const root = ReactDOM.createRoot(x);

root.render(<App/>);
// root.render(<Mosh/>)




